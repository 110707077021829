// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Helper from "./Helper";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function archive(prim) {
  return Helper.archive();
}

function file(prim0, prim1, prim2, prim3) {
  return Helper.file(prim0, prim1, prim2, prim3 !== undefined ? Caml_option.valFromOption(prim3) : undefined);
}

function folder(prim0, prim1) {
  return Helper.folder(prim0, prim1);
}

function blob(prim) {
  return Helper.blob(prim);
}

export {
  archive ,
  file ,
  folder ,
  blob ,
  
}
/* ./Helper Not a pure module */
