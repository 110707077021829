import * as monaco from "monaco-editor"
import { setDiagnosticsOptions } from 'monaco-yaml';
import { courseSchema } from "../../content/JsonSchema";
import { sectionSchema } from "../../content/JsonSchema";
import { chapterSchema } from "../../content/JsonSchema";
/* const bf = window.MonacoEnvironment.getWorkerUrl
window["MonacoEnvironment"]["getWorkerUrl"] = (moduleId,label) =>{
    if(label==='yaml'){
        return 'monaco-yaml/lib/esm/yaml.worker';
    }
    const res = bf(moduleId,label)
    console.log({res})
    return res
} */
// configure the JSON language support with schemas and schema associations
const schemas = [{
    uri: courseSchema.uri,
    fileMatch: ["course.json"],
    schema: courseSchema.schema
},
{
    uri: sectionSchema.uri,
    fileMatch: ["section.json"],
    schema: sectionSchema.schema
},
{
    uri: chapterSchema.uri,
    fileMatch: ["chapter.json"],
    schema: chapterSchema.schema
},
]
monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
    validate: true,
    schemaValidation: 'error',
    schemas
});
setDiagnosticsOptions({
    enableSchemaRequest: false,
    schemaValidation: 'error',
    hover: true,
    completion: true,
    validate: true,
    format: true,
    schemas,
});

export default 'config'