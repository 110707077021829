// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as JsYaml from "js-yaml";
import * as Config from "./config";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MonacoEditor from "monaco-editor";

function toString(lang) {
  if (lang) {
    return "yaml";
  } else {
    return "json";
  }
}

function fromString(lang) {
  switch (lang) {
    case "json" :
        return /* Json */0;
    case "yaml" :
        return /* Yaml */1;
    default:
      return /* Json */0;
  }
}

function codeConvert(code, prevLang, lang) {
  try {
    if (prevLang) {
      if (lang) {
        return JsYaml.dump(JSON.parse(code));
      } else {
        return JSON.stringify(JsYaml.load(code), null, 2);
      }
    } else if (lang) {
      return JsYaml.dump(JSON.parse(code));
    } else {
      return JSON.stringify(JSON.parse(code), null, 2);
    }
  }
  catch (exn){
    return code;
  }
}

var Lang = {
  toString: toString,
  fromString: fromString,
  codeConvert: codeConvert
};

function write(m, text) {
  return m.trigger("keyboard", "type", {
              text: text
            });
}

function language(m) {
  return fromString(m._languageIdentifier.language);
}

function disposeModels(monaco) {
  Belt_Array.map(monaco.getModels(), (function (m) {
          return m.dispose();
        }));
  
}

function composeModel(code, lang, uriPath) {
  var uri = Curry._1(MonacoEditor.Uri.parse, uriPath);
  var langStr = lang ? "yaml" : "json";
  var code$1 = Belt_Option.getWithDefault(codeConvert(code, lang, lang), "");
  var modelObj = Curry._3(MonacoEditor.editor.createModel, code$1, langStr, uri);
  return [
          uri,
          modelObj
        ];
}

function convertModel(editorUtil, model, newLang, code, uriPath, param) {
  var code$1 = Belt_Option.getWithDefault(code, model.getValue());
  var prevLang = fromString(model._languageIdentifier.language);
  console.log([
        prevLang ? "yaml" : "json",
        newLang ? "yaml" : "json"
      ]);
  return Belt_Option.map(Belt_Option.map(codeConvert(code$1, prevLang, newLang), (function (converted) {
                    disposeModels(editorUtil);
                    return composeModel(converted, newLang, uriPath);
                  })), (function (param) {
                return param[1];
              }));
}

function Monaco(Props) {
  var lang = Props.lang;
  var code = Props.code;
  var path = Props.path;
  var actions = Props.actions;
  var notify = Props.notify;
  var getCode = Props.getCode;
  var editorRef = React.useRef(null);
  var match = React.useState(function () {
        
      });
  var setEditor = match[1];
  var editorOpt = match[0];
  React.useEffect((function () {
          console.log({
                editorPath: path
              });
          
        }), [path]);
  React.useEffect((function () {
          var modelOpt = Belt_Option.flatMap(Belt_Option.map(editorOpt, (function (e) {
                      return e.getModel();
                    })), (function (model) {
                  return convertModel(MonacoEditor.editor, model, lang, code, path, undefined);
                }));
          if (editorOpt !== undefined) {
            if (modelOpt !== undefined) {
              editorOpt.setModel(modelOpt);
            } else {
              Curry._2(notify, /* Error */3, {
                    message: "Invalid code",
                    description: "Cannot convert invalid code"
                  });
            }
          }
          
        }), [
        code,
        path
      ]);
  React.useEffect((function () {
          Belt_Array.map(actions, (function (a) {
                  return Belt_Option.map(editorOpt, (function (e) {
                                return e.addAction(a);
                              }));
                }));
          
        }), [
        actions.length,
        editorOpt
      ]);
  React.useEffect((function () {
          var modelOpt = Belt_Option.flatMap(Belt_Option.map(editorOpt, (function (e) {
                      return e.getModel();
                    })), (function (model) {
                  return convertModel(MonacoEditor.editor, model, lang, undefined, path, undefined);
                }));
          if (editorOpt !== undefined) {
            if (modelOpt !== undefined) {
              editorOpt.setModel(modelOpt);
              Curry._1(getCode, (function (param) {
                      return codeConvert(modelOpt.getValue(), lang, /* Json */0);
                    }));
            } else {
              Curry._2(notify, /* Error */3, {
                    message: "Invalid code",
                    description: "Cannot convert invalid code"
                  });
            }
          }
          
        }), [
        editorOpt,
        lang,
        path
      ]);
  var setEditorRef = function (element) {
    editorRef.current = element;
    var dom = editorRef.current;
    if (!(dom == null)) {
      if (editorOpt !== undefined) {
        return ;
      }
      var match = composeModel(code, lang, path);
      var e = Curry._3(MonacoEditor.editor.create, dom, {
            model: match[1]
          }, match[0]);
      return Curry._1(setEditor, (function (param) {
                    return e;
                  }));
    }
    
  };
  return React.createElement("div", {
              ref: setEditorRef,
              style: {
                height: "100%"
              }
            });
}

var config;

var make = Monaco;

export {
  Lang ,
  write ,
  config ,
  language ,
  disposeModels ,
  composeModel ,
  convertModel ,
  make ,
  
}
/*  Not a pure module */
