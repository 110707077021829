// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Antd from "antd";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Content from "../../../content/Content.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

function ContentTree(Props) {
  var content = Props.content;
  var onMoveAfter = Props.onMoveAfter;
  var onSelect = Props.onSelect;
  var notify = Props.notify;
  var tree = React.useMemo((function () {
          return [{
                    title: "root",
                    key: Content.root_path,
                    children: Content.tree(content, undefined, undefined)
                  }];
        }), [content]);
  var match = React.useState(function () {
        return [];
      });
  var setExpanded = match[1];
  var unexpandKey = function (key) {
    return Curry._1(setExpanded, (function (e) {
                  return Belt_Array.keep(e, (function (k) {
                                return k !== key;
                              }));
                }));
  };
  var onExpand = function (newExp) {
    return Curry._1(setExpanded, (function (param) {
                  return newExp;
                }));
  };
  var onDrop = function ($$event) {
    if ($$event.dropToGap) {
      var currKey = $$event.dragNode.key;
      var targetKey = $$event.node.key;
      unexpandKey(currKey);
      unexpandKey(targetKey);
      Curry._2(onMoveAfter, targetKey, currKey);
    } else {
      Curry._2(notify, /* Error */3, {
            message: "Error moving",
            description: "Cannot move here"
          });
    }
    
  };
  return React.createElement(Antd.Tree, {
              showLine: {
                showLeafIcon: false
              },
              showLeafIcon: false,
              draggable: true,
              treeData: tree,
              expandedKeys: match[0],
              onSelect: onSelect,
              onDrop: onDrop,
              onExpand: onExpand
            });
}

var make = ContentTree;

export {
  make ,
  
}
/* antd Not a pure module */
