// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Antd from "antd";

function $$Option(Props) {
  var value = Props.value;
  var children = Props.children;
  return Antd.Select.Option(value, children);
}

var make = $$Option;

export {
  make ,
  
}
/* antd Not a pure module */
