// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Antd from "antd";
import * as React from "react";
import * as Css from "@emotion/css";

var container = Css.css({
      height: 64,
      padding: 16
    });

var rightCol = Css.css({
      "& > *": {
        float: "right",
        marginLeft: "16px!important"
      }
    });

var Style = {
  height: 64,
  container: container,
  rightCol: rightCol
};

function Footer(Props) {
  var className = Props.className;
  var leftPortalCallback = Props.leftPortalCallback;
  var rightPortalCallback = Props.rightPortalCallback;
  return React.createElement("div", {
              className: className + " " + container
            }, React.createElement(Antd.Row, {
                  children: null
                }, React.createElement(Antd.Col, {
                      span: 6,
                      children: React.createElement(React.Fragment, undefined),
                      ref: leftPortalCallback
                    }), React.createElement(Antd.Col, {
                      span: 12,
                      children: React.createElement(React.Fragment, undefined)
                    }), React.createElement(Antd.Col, {
                      span: 6,
                      className: rightCol,
                      ref: rightPortalCallback
                    })));
}

var make = Footer;

export {
  Style ,
  make ,
  
}
/* container Not a pure module */
