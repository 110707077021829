// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Antd from "antd";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Editor from "./Editor/Editor.bs.js";
import * as Footer from "./Footer/Footer.bs.js";
import * as Header from "./Header/Header.bs.js";
import * as Content from "../../content/Content.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContentTree from "./ContentTree/ContentTree.bs.js";
import * as Css from "@emotion/css";
import * as $$Notification from "../../lib/antd/notification/Notification.bs.js";
import * as ReactPortal from "react-portal";
import * as ReactInspector from "react-inspector";
import * as Icons from "@ant-design/icons";

var container = Css.css({
      height: "100vh",
      width: "100%",
      overflow: "hidden"
    });

var header = Css.css({
      height: "15vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    });

var bodyContainer = Css.css({
      display: "grid",
      gridTemplateColumns: "1fr 3fr 2fr",
      gridTemplateRows: "minmax(calc(85vh - " + String(Footer.Style.height) + "px), calc(85vh - " + String(Footer.Style.height) + "px))"
    });

var treeContainer = Css.css({
      overflow: "scroll"
    });

var bottomHeaderPortal = Css.css({
      display: "flex",
      flexDirection: "row-reverse",
      justyfyContent: "space-around",
      "& button": {
        marginRight: "10px"
      }
    });

var footer = Css.css({
      height: "32px"
    });

var objectInspector = Css.css({
      overflow: "scroll"
    });

var Style = {
  container: container,
  header: header,
  bodyContainer: bodyContainer,
  treeContainer: treeContainer,
  bottomHeaderPortal: bottomHeaderPortal,
  footer: footer,
  objectInspector: objectInspector
};

function CodeScreen(Props) {
  var match = React.useState(function () {
        return Content.empty(undefined);
      });
  var setContent = match[1];
  var content = match[0];
  var contentEmpty = Content.isEmpty(content);
  var match$1 = React.useState(function () {
        return Content.root_path;
      });
  var setPath = match$1[1];
  var path = match$1[0];
  var selectPath = function (selected) {
    Belt_Option.map(Belt_Array.get(selected, 0), (function (p) {
            return Curry._1(setPath, (function (param) {
                          return p;
                        }));
          }));
    
  };
  var deleteSelected = function (param) {
    return Curry._1(setContent, (function (c) {
                  return Content.$$delete(c, path);
                }));
  };
  var onFileUpload = function (v) {
    var v$1 = Content.Unit.fromFile(v);
    Curry._1(setContent, (function (c) {
            return Content.addMany(c, v$1);
          }));
    
  };
  var match$2 = React.useMemo((function () {
          return Belt_Option.getWithDefault(Belt_Option.map(Content.getUnit(content, path), (function (u) {
                            return [
                                    u.code,
                                    Content.Unit.kindToSchema(u.kind)
                                  ];
                          })), [
                      "{\"message\": \"NOTHING SELECTED\"}",
                      JSON.parse("{\"message\": \"NOTHING SELECTED\"}")
                    ]);
        }), [path]);
  var schema = match$2[1];
  var onMoveAfter = function (targetKey, currKey) {
    return Curry._1(setContent, (function (param) {
                  return Content.moveAfter(content, targetKey, currKey);
                }));
  };
  var match$3 = React.useState(function () {
        
      });
  var setHeaderBottomPortal = match$3[1];
  var updateHeaderBottomPortal = function (el) {
    return Curry._1(setHeaderBottomPortal, (function (param) {
                  if (el == null) {
                    return ;
                  } else {
                    return Caml_option.some(el);
                  }
                }));
  };
  var match$4 = React.useState(function () {
        
      });
  var setHeaderExtraPortal = match$4[1];
  var updateHeaderExtraPortal = function (el) {
    return Curry._1(setHeaderExtraPortal, (function (param) {
                  if (el == null) {
                    return ;
                  } else {
                    return Caml_option.some(el);
                  }
                }));
  };
  var match$5 = React.useState(function () {
        
      });
  var setFooterLeftPortal = match$5[1];
  var updateFooterLeftPortal = function (el) {
    return Curry._1(setFooterLeftPortal, (function (param) {
                  if (el == null) {
                    return ;
                  } else {
                    return Caml_option.some(el);
                  }
                }));
  };
  var match$6 = React.useState(function () {
        
      });
  var setFooterRightPortal = match$6[1];
  var updateFooterRightPortal = function (el) {
    return Curry._1(setFooterRightPortal, (function (param) {
                  if (el == null) {
                    return ;
                  } else {
                    return Caml_option.some(el);
                  }
                }));
  };
  var onSave = function (json) {
    if (path === "/") {
      return $$Notification.notify(/* Info */1, {
                  message: "Unable to save",
                  description: "No target selected"
                });
    } else {
      return Curry._1(setContent, (function (curr) {
                    var newOpt = Content.set(curr, path, json);
                    if (newOpt !== undefined) {
                      $$Notification.notify(/* Success */0, {
                            message: "Save",
                            description: "Saved successfully"
                          });
                      return newOpt;
                    } else {
                      $$Notification.notify(/* Error */3, {
                            message: "Error while saving",
                            description: "Invalid input"
                          });
                      return curr;
                    }
                  }));
    }
  };
  var onCreate = function (param) {
    Curry._1(setContent, (function (c) {
            var withNewChildOpt = Content.addChild(c, path);
            if (withNewChildOpt !== undefined) {
              return withNewChildOpt;
            } else {
              $$Notification.notify(/* Info */1, {
                    message: "Cannot Add Child",
                    description: "This entry cannot have children"
                  });
              return content;
            }
          }));
    
  };
  var onDownload = function (param) {
    var success = Belt_Option.isSome(Content.download(content, "content.zip", undefined));
    if (!success) {
      return $$Notification.notify(/* Info */1, {
                  message: "Cannot Download",
                  description: "May be nothing to download"
                });
    }
    
  };
  var tmp = {
    code: match$2[0],
    path: path,
    schema: schema,
    notify: $$Notification.notify,
    onSave: onSave
  };
  var tmp$1 = match$3[0];
  if (tmp$1 !== undefined) {
    tmp.savePortalOpt = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = match$6[0];
  if (tmp$2 !== undefined) {
    tmp.utilsPortalOpt = Caml_option.valFromOption(tmp$2);
  }
  return React.createElement("div", {
              className: container
            }, React.createElement(Header.make, {
                  extraPortal: React.createElement("div", {
                        ref: updateHeaderExtraPortal
                      }),
                  bottomPortal: React.createElement("div", {
                        ref: updateHeaderBottomPortal,
                        className: bottomHeaderPortal
                      }, React.createElement(Antd.Tooltip, {
                            title: "Delete Selected",
                            children: React.createElement(Antd.Button, {
                                  type: "primary",
                                  icon: React.createElement(Icons.DeleteOutlined, {}),
                                  danger: true,
                                  onClick: deleteSelected
                                })
                          }), React.createElement(Antd.Tooltip, {
                            title: "Add Child",
                            children: React.createElement(Antd.Button, {
                                  type: "primary",
                                  icon: React.createElement(Icons.PlusOutlined, {}),
                                  onClick: onCreate
                                })
                          })),
                  onFileUpload: onFileUpload,
                  onDownload: onDownload,
                  className: header
                }), React.createElement("div", {
                  className: bodyContainer
                }, contentEmpty ? React.createElement(Antd.Empty, {
                        image: Antd.Empty.PRESENTED_IMAGE_SIMPLE
                      }) : React.createElement("div", {
                        className: treeContainer
                      }, React.createElement(ContentTree.make, {
                            content: content,
                            onMoveAfter: onMoveAfter,
                            onSelect: selectPath,
                            notify: $$Notification.notify
                          })), React.createElement(Editor.make, tmp), React.createElement("div", {
                      className: objectInspector
                    }, React.createElement(ReactInspector.ObjectInspector, {
                          data: schema,
                          expandLevel: 2
                        }))), React.createElement(Footer.make, {
                  className: footer,
                  leftPortalCallback: updateFooterLeftPortal,
                  rightPortalCallback: updateFooterRightPortal
                }), Belt_Option.getWithDefault(Belt_Option.map(match$5[0], (function (node) {
                        return React.createElement(ReactPortal.Portal, {
                                    node: node,
                                    children: React.createElement(Antd.Button, {
                                          children: "Docs",
                                          type: "link",
                                          href: "docs/guide.pdf"
                                        })
                                  });
                      })), React.createElement(React.Fragment, undefined)));
}

var make = CodeScreen;

export {
  Style ,
  make ,
  
}
/* container Not a pure module */
