// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Antd from "antd";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$FileReader from "../../../lib/file/FileReader.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Icons from "@ant-design/icons";

function Header(Props) {
  var extraPortal = Props.extraPortal;
  var bottomPortal = Props.bottomPortal;
  var onFileUpload = Props.onFileUpload;
  var onDownload = Props.onDownload;
  var className = Props.className;
  var tmp = {
    ghost: false,
    title: "Codefinity Content Editor",
    subTitle: "Content Dev",
    extra: [
      React.createElement(Antd.Upload, {
            onRemove: (function (file) {
                console.log({
                      file: file
                    });
                
              }),
            beforeUpload: (function (file) {
                $$FileReader.readAsText(file).then(function (s) {
                      Curry._1(onFileUpload, s);
                      return Promise.resolve(undefined);
                    });
                return false;
              }),
            children: React.createElement(Antd.Tooltip, {
                  title: "Upload",
                  children: React.createElement(Antd.Button, {
                        icon: React.createElement(Icons.UploadOutlined, {})
                      })
                }),
            showUploadList: false,
            directory: true,
            key: "upload"
          }),
      React.createElement(Antd.Tooltip, {
            title: "Download",
            children: React.createElement(Antd.Button, {
                  icon: React.createElement(Icons.FileZipOutlined, {}),
                  onClick: onDownload
                }),
            key: "Download"
          }),
      React.createElement("div", {
            key: "extraPortal"
          }, Belt_Option.getWithDefault(extraPortal, React.createElement(React.Fragment, undefined)))
    ],
    children: React.createElement("div", {
          style: {
            display: "flex"
          }
        }, Belt_Option.getWithDefault(bottomPortal, React.createElement(React.Fragment, undefined)))
  };
  if (className !== undefined) {
    tmp.className = className;
  }
  return React.createElement(Antd.PageHeader, tmp);
}

var make = Header;

export {
  make ,
  
}
/* antd Not a pure module */
