// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Helper from "./Helper";

function validate(prim0, prim1) {
  return Helper.validate(prim0, prim1);
}

export {
  validate ,
  
}
/* ./Helper Not a pure module */
