// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import LogoSvg from "./logo.svg";
import * as CodeScreen from "../CodeScreen/CodeScreen.bs.js";
import * as Css from "@emotion/css";

import 'antd/dist/antd.css';
;

var logo = LogoSvg;

var container = Css.css({
      position: "absolute",
      top: "0",
      width: "100vw",
      height: "100vh"
    });

var Style = {
  container: container
};

function App(Props) {
  console.log(undefined);
  return React.createElement("div", {
              className: container
            }, React.createElement(CodeScreen.make, {}));
}

var make = App;

export {
  logo ,
  Style ,
  make ,
  
}
/*  Not a pure module */
