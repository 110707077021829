// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Helper from "./Helper";

var $$File = {};

function readAsText(prim) {
  return Helper.readAsText(prim);
}

export {
  $$File ,
  readAsText ,
  
}
/* ./Helper Not a pure module */
