// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Helper from "./Helper";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function parseOpt(s) {
  try {
    return Caml_option.some(JSON.parse(s));
  }
  catch (exn){
    return ;
  }
}

function removeFields(prim0, prim1) {
  return Helper.removeFields(prim0, prim1);
}

function set(prim0, prim1, prim2) {
  return Helper.set(prim0, prim1, prim2);
}

export {
  parseOpt ,
  removeFields ,
  set ,
  
}
/* ./Helper Not a pure module */
