// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JSF from "../lib/jsf/JSF.bs.js";
import * as Zip from "../lib/jszip/Zip.bs.js";
import * as Json from "../lib/json/Json.bs.js";
import * as Uuid from "uuid";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Helper from "../lib/fun/Helper.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Downloadjs from "downloadjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsonSchema from "./JsonSchema";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as PathBrowserify from "path-browserify";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";

var root_path = "/";

var courseSchemaObj = JsonSchema.courseSchema;

var sectionSchemaObj = JsonSchema.sectionSchema;

var chapterSchemaObj = JsonSchema.chapterSchema;

function kindToSchema(kind) {
  switch (kind) {
    case /* Course */0 :
        return courseSchemaObj.schema;
    case /* Section */1 :
        return sectionSchemaObj.schema;
    case /* Chapter */2 :
        return chapterSchemaObj.schema;
    
  }
}

var fileNameContentKindMapping = Belt_MapString.fromArray([
      [
        "courses.json",
        /* Course */0
      ],
      [
        "sections.json",
        /* Section */1
      ],
      [
        "chapters.json",
        /* Chapter */2
      ]
    ]);

function getParentId(kind, json) {
  var parentIdField;
  switch (kind) {
    case /* Course */0 :
        parentIdField = "%none%";
        break;
    case /* Section */1 :
        parentIdField = "courseId";
        break;
    case /* Chapter */2 :
        parentIdField = "sectionId";
        break;
    
  }
  return Belt_Option.flatMap(Js_dict.get(json, parentIdField), Js_json.decodeString);
}

function getIdFromJson(json) {
  return Belt_Option.flatMap(Js_dict.get(json, "id"), Js_json.decodeString);
}

function courseIdFromOriginPath(p) {
  return Belt_Array.get(Belt_Array.slice(PathBrowserify.dirname(p).split("/"), -1, 1), 0);
}

function genPath(originPath, id, kind, json) {
  var parentId = Belt_Option.getWithDefault(getParentId(kind, json), "");
  var courseId = Belt_Option.getWithDefault(courseIdFromOriginPath(originPath), "");
  var tmp;
  switch (kind) {
    case /* Course */0 :
        tmp = [
          root_path,
          id,
          "course.json"
        ];
        break;
    case /* Section */1 :
        tmp = [
          root_path,
          parentId,
          "sections",
          id,
          "section.json"
        ];
        break;
    case /* Chapter */2 :
        tmp = [
          root_path,
          courseId,
          "sections",
          parentId,
          "chapters",
          id,
          "chapter.json"
        ];
        break;
    
  }
  return Caml_splice_call.spliceApply(PathBrowserify.join, [tmp]);
}

function $$new(kind, json) {
  return Belt_Option.map(JSON.stringify(json), (function (code) {
                return {
                        kind: kind,
                        code: code,
                        json: json
                      };
              }));
}

function belongsToKind(t, kind) {
  return t.kind === kind;
}

function fromJson(name, originPath, obj) {
  var decodedOpt = Js_json.decodeObject(obj);
  var idOpt = Belt_Option.flatMap(Belt_Option.flatMap(decodedOpt, (function (d) {
              return Js_dict.get(d, "id");
            })), Js_json.decodeString);
  var kindOpt = Belt_MapString.get(fileNameContentKindMapping, name);
  if (!(Belt_Option.isSome(kindOpt) && Belt_Option.isSome(idOpt) && Belt_Option.isSome(decodedOpt))) {
    return ;
  }
  var id = Belt_Option.getExn(idOpt);
  var jsonObj = Belt_Option.getExn(decodedOpt);
  var json = Json.removeFields(jsonObj, [
        "sectionId",
        "courseId"
      ]);
  var code = Belt_Option.getExn(JSON.stringify(json));
  var kind = Belt_Option.getExn(kindOpt);
  return [
          genPath(originPath, id, kind, jsonObj),
          {
            kind: kind,
            code: code,
            json: json
          }
        ];
}

function updateIdInPath(path, id) {
  var arr = path.split(root_path);
  var penultimateIdx = arr.length - 2 | 0;
  var __x = Helper.$$Array.set(arr, penultimateIdx, id);
  return Caml_splice_call.spliceApply(PathBrowserify.join, [Belt_Array.concat([root_path], __x)]);
}

function updateJsonExn(path, kind, json) {
  var newPath = updateIdInPath(path, Belt_Option.getExn(getIdFromJson(json)));
  return [
          newPath,
          {
            kind: kind,
            code: Belt_Option.getExn(JSON.stringify(json)),
            json: json
          }
        ];
}

function fromFile(f) {
  return Belt_Array.map(Belt_Array.keep(Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Json.parseOpt(f.content), Js_json.decodeArray), (function (a) {
                            var partial_arg = f.path;
                            var partial_arg$1 = f.name;
                            return Belt_Array.map(a, (function (param) {
                                          return fromJson(partial_arg$1, partial_arg, param);
                                        }));
                          })), []), Belt_Option.isSome), Belt_Option.getExn);
}

function isChildCallback(parentPath, childPath) {
  return PathBrowserify.dirname(parentPath) === PathBrowserify.dirname(PathBrowserify.dirname(PathBrowserify.dirname(childPath)));
}

function dirname(p) {
  if (p === root_path) {
    return p;
  } else {
    return PathBrowserify.dirname(p);
  }
}

function getParentPath(p) {
  return dirname(dirname(dirname(p)));
}

function getId(key) {
  return PathBrowserify.basename(dirname(key));
}

function isChild(parentKey, childKey) {
  return dirname(parentKey) === dirname(dirname(dirname(childKey)));
}

function keyToChildKind(key) {
  var name = PathBrowserify.basename(key);
  switch (name) {
    case "chapter.json" :
        return ;
    case "course.json" :
        return /* Section */1;
    case "section.json" :
        return /* Chapter */2;
    default:
      return /* Course */0;
  }
}

function genChild(parentKey) {
  var parentPath = dirname(parentKey);
  var id = Uuid.v4();
  var jsonOpt = Belt_Option.map(Belt_Option.map(Belt_Option.map(keyToChildKind(parentKey), kindToSchema), JSF.generate), (function (o) {
          return Json.set(o, "id", id);
        }));
  var kindOpt = keyToChildKind(parentKey);
  if (jsonOpt === undefined) {
    return ;
  }
  if (kindOpt === undefined) {
    return ;
  }
  var entryOpt = $$new(kindOpt, Caml_option.valFromOption(jsonOpt));
  switch (kindOpt) {
    case /* Course */0 :
        if (entryOpt !== undefined) {
          return [
                  PathBrowserify.join(parentPath, id, "course.json"),
                  entryOpt
                ];
        } else {
          return ;
        }
    case /* Section */1 :
        if (entryOpt !== undefined) {
          return [
                  PathBrowserify.join(parentPath, "sections", id, "section.json"),
                  entryOpt
                ];
        } else {
          return ;
        }
    case /* Chapter */2 :
        if (entryOpt !== undefined) {
          return [
                  PathBrowserify.join(parentPath, "chapters", id, "chapter.json"),
                  entryOpt
                ];
        } else {
          return ;
        }
    
  }
}

function isDescendant(childKey, parentKey) {
  return childKey.includes(dirname(parentKey));
}

function parentId(key) {
  return PathBrowserify.basename(dirname(dirname(dirname(key))));
}

function parentIdFieldOpt(t, key) {
  var match = t.kind;
  switch (match) {
    case /* Course */0 :
        return ;
    case /* Section */1 :
        return [
                "courseId",
                PathBrowserify.basename(dirname(dirname(dirname(key))))
              ];
    case /* Chapter */2 :
        return [
                "sectionId",
                PathBrowserify.basename(dirname(dirname(dirname(key))))
              ];
    
  }
}

function serialiazeOpt(u, key) {
  return Belt_Option.getWithDefault(Belt_Option.map(parentIdFieldOpt(u, key), (function (param) {
                    return Json.set(u.json, param[0], param[1]);
                  })), u.json);
}

function file(folder, kind, content) {
  var name;
  switch (kind) {
    case /* Course */0 :
        name = "courses.json";
        break;
    case /* Section */1 :
        name = "sections.json";
        break;
    case /* Chapter */2 :
        name = "chapters.json";
        break;
    
  }
  return Zip.file(folder, name, content, {
              base64: false
            });
}

function replaceAncestor(childKey, oldAncestorKey, newAncestorKey) {
  return childKey.replace(dirname(oldAncestorKey), dirname(newAncestorKey));
}

var Unit = {
  courseSchemaObj: courseSchemaObj,
  sectionSchemaObj: sectionSchemaObj,
  chapterSchemaObj: chapterSchemaObj,
  kindToSchema: kindToSchema,
  fileNameContentKindMapping: fileNameContentKindMapping,
  getParentId: getParentId,
  getIdFromJson: getIdFromJson,
  courseIdFromOriginPath: courseIdFromOriginPath,
  genPath: genPath,
  $$new: $$new,
  belongsToKind: belongsToKind,
  fromJson: fromJson,
  updateIdInPath: updateIdInPath,
  updateJsonExn: updateJsonExn,
  fromFile: fromFile,
  isChildCallback: isChildCallback,
  dirname: dirname,
  getSelfPath: dirname,
  getParentPath: getParentPath,
  getId: getId,
  isChild: isChild,
  keyToChildKind: keyToChildKind,
  genChild: genChild,
  isDescendant: isDescendant,
  parentId: parentId,
  parentIdFieldOpt: parentIdFieldOpt,
  serialiazeOpt: serialiazeOpt,
  file: file,
  replaceAncestor: replaceAncestor
};

function $$new$1(dict) {
  return {
          fs: dict
        };
}

function entries(all) {
  return Js_dict.entries(all.fs);
}

function empty(param) {
  return {
          fs: {}
        };
}

function isEmpty(c) {
  return Js_dict.entries(c.fs).length === 0;
}

function addMany(all, entries) {
  return {
          fs: Js_dict.fromArray(Belt_Array.concat(Js_dict.entries(all.fs), entries))
        };
}

function getUnit(all, key) {
  return Js_dict.get(all.fs, key);
}

function getCode(all, key) {
  return Belt_Option.map(Js_dict.get(all.fs, key), (function (u) {
                return u.code;
              }));
}

function childrenOf(c, key) {
  return {
          fs: Js_dict.fromArray(Belt_Array.keep(Js_dict.entries(c.fs), (function (param) {
                      return isChild(key, param[0]);
                    })))
        };
}

function set(all, key, json) {
  try {
    var kind = Belt_Option.getExn(Belt_Option.map(Js_dict.get(all.fs, key), (function (u) {
                return u.kind;
              })));
    var newEntry = updateJsonExn(key, kind, json);
    var newKey = newEntry[0];
    return {
            fs: Js_dict.fromArray(Belt_Array.map(Js_dict.entries(all.fs), (function (param) {
                        var k = param[0];
                        if (k === key) {
                          return newEntry;
                        }
                        var v = param[1];
                        if (k.includes(dirname(key))) {
                          return [
                                  replaceAncestor(k, key, newKey),
                                  v
                                ];
                        } else {
                          return [
                                  k,
                                  v
                                ];
                        }
                      })))
          };
  }
  catch (err){
    return ;
  }
}

function tree(c, root, param) {
  var courses = childrenOf(c, root_path);
  var c$1 = Belt_Option.getWithDefault(root, courses);
  return Belt_Array.map(Js_dict.entries(c$1.fs), (function (param) {
                return treeItem(c, param);
              }));
}

function treeItem(all, param) {
  var key = param[0];
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(param[1].json, "label"), Js_json.decodeString), "NO_TITLE_SPECIFIED");
  var childArray = childrenOf(all, key);
  var children = isEmpty(childArray) ? [] : tree(all, childArray, undefined);
  return {
          title: title,
          key: key,
          children: children
        };
}

function movePathTo(currKey, targetPath) {
  var parentPath = dirname(dirname(dirname(currKey)));
  if (parentPath === root_path) {
    return currKey;
  } else {
    return PathBrowserify.join(targetPath, Belt_Option.getExn(Belt_Array.get(currKey.split(parentPath), 1)));
  }
}

function addAfter(all, targetKey, param, $staropt$star, param$1) {
  var currKey = param[0];
  var replace = $staropt$star !== undefined ? $staropt$star : false;
  var newKey = replace ? movePathTo(currKey, dirname(dirname(dirname(targetKey)))) : currKey;
  var newEntry_1 = param[1];
  var newEntry = [
    newKey,
    newEntry_1
  ];
  var arr = Js_dict.entries(all.fs);
  return {
          fs: Js_dict.fromArray(replace ? Helper.$$Array.flatMap(arr, (function (param) {
                        var e = param[1];
                        var key = param[0];
                        if (key === targetKey) {
                          return [
                                  [
                                    key,
                                    e
                                  ],
                                  newEntry
                                ];
                        } else if (key === currKey) {
                          return [];
                        } else {
                          return [[
                                    key,
                                    e
                                  ]];
                        }
                      })) : Belt_Array.concat(arr, [newEntry]))
        };
}

function moveAfter(all, targetKey, currKey) {
  var entryOpt = Js_dict.get(all.fs, currKey);
  var res = entryOpt !== undefined ? addAfter(all, targetKey, [
          currKey,
          entryOpt
        ], true, undefined) : all;
  console.log({
        movedAfter: res
      });
  return res;
}

function addChild(all, parentKey) {
  return Belt_Option.map(Belt_Option.map(genChild(parentKey), (function (param, param$1, param$2) {
                    return addAfter(all, parentKey, param, param$1, param$2);
                  })), (function (c) {
                return Curry._2(c, undefined, undefined);
              }));
}

function $$delete(all, key) {
  var selfPath = dirname(key);
  return {
          fs: Js_dict.fromArray(Belt_Array.keep(Js_dict.entries(all.fs), (function (param) {
                      return !param[0].startsWith(selfPath);
                    })))
        };
}

function coursesContent(all) {
  return Belt_Array.map(Belt_Array.keep(Js_dict.entries(all.fs), (function (param) {
                    return param[1].kind === /* Course */0;
                  })), (function (param) {
                var courseKey = param[0];
                return [
                        courseKey,
                        {
                          fs: Js_dict.fromArray(Belt_Array.keep(Js_dict.entries(all.fs), (function (param) {
                                      return param[0].includes(dirname(courseKey));
                                    })))
                        }
                      ];
              }));
}

function serializeByKind(all, kind) {
  return JSON.stringify(Belt_Array.map(Belt_Array.keep(Js_dict.entries(all.fs), (function (param) {
                        return param[1].kind === kind;
                      })), (function (param) {
                    return serialiazeOpt(param[1], param[0]);
                  })), null, 2);
}

function courseFolderExn(param, zip) {
  var all = param[1];
  var folder = Zip.folder(zip, PathBrowserify.basename(dirname(param[0])));
  console.log({
        folder: folder
      });
  Belt_Array.map(Belt_Array.map([
            /* Course */0,
            /* Section */1,
            /* Chapter */2
          ], (function (kind) {
              return [
                      kind,
                      serializeByKind(all, kind)
                    ];
            })), (function (param) {
          return file(folder, param[0], param[1]);
        }));
  return folder;
}

function download(all, archiveNameOpt, param) {
  var archiveName = archiveNameOpt !== undefined ? archiveNameOpt : "content.zip";
  try {
    if (isEmpty(all)) {
      throw {
            RE_EXN_ID: "Not_found",
            Error: new Error()
          };
    }
    var archive = Zip.archive(undefined);
    Belt_Array.map(coursesContent(all), (function (c) {
            return courseFolderExn(c, archive);
          }));
    return Caml_option.some((Zip.blob(archive).then(function (b) {
                      Downloadjs(b, archiveName, "application/zip");
                      
                    }), undefined));
  }
  catch (exn){
    return ;
  }
}

export {
  root_path ,
  Unit ,
  $$new$1 as $$new,
  entries ,
  empty ,
  isEmpty ,
  addMany ,
  getUnit ,
  getCode ,
  childrenOf ,
  set ,
  tree ,
  treeItem ,
  movePathTo ,
  addAfter ,
  moveAfter ,
  addChild ,
  $$delete ,
  coursesContent ,
  serializeByKind ,
  courseFolderExn ,
  download ,
  
}
/* courseSchemaObj Not a pure module */
