// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Helper from "./Helper";

function kindToString(k) {
  switch (k) {
    case /* Success */0 :
        return "success";
    case /* Info */1 :
        return "info";
    case /* Warning */2 :
        return "warning";
    case /* Error */3 :
        return "error";
    
  }
}

var notifyHelper = Helper.notify;

function notify(kind, t) {
  return notifyHelper(kindToString(kind), t);
}

export {
  kindToString ,
  notifyHelper ,
  notify ,
  
}
/* notifyHelper Not a pure module */
