// Generated by ReScript, PLEASE EDIT WITH CARE

import JsonSchemaFaker from "json-schema-faker";

function generate(x) {
  return JsonSchemaFaker.generate(x);
}

export {
  generate ,
  
}
/* json-schema-faker Not a pure module */
