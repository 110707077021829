// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "../../../lib/json/Json.bs.js";
import * as Antd from "antd";
import * as Uuid from "uuid";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Monaco from "../../../lib/monaco/Monaco.bs.js";
import * as $$Option from "../../../lib/antd/Option.bs.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactPortal from "react-portal";
import * as Icons from "@ant-design/icons";
import * as JsonSchemaValidator from "../../../lib/schema-validator/JsonSchemaValidator.bs.js";

function Editor(Props) {
  var code = Props.code;
  var path = Props.path;
  var schema = Props.schema;
  var savePortalOpt = Props.savePortalOpt;
  var utilsPortalOpt = Props.utilsPortalOpt;
  var notify = Props.notify;
  var onSave = Props.onSave;
  var match = React.useState(function () {
        return /* Json */0;
      });
  var setLang = match[1];
  var lang = match[0];
  var updateLang = function (newLang) {
    return Curry._1(setLang, (function (param) {
                  return newLang;
                }));
  };
  var getCodeRef = React.useRef(function (param) {
        return code;
      });
  var getCode = function (cb) {
    getCodeRef.current = cb;
    
  };
  var onSaveClick = function (param) {
    var currentCode = Belt_Option.getExn(Curry._1(getCodeRef.current, undefined));
    var validationErrors = JsonSchemaValidator.validate(Json.parseOpt(currentCode), schema);
    if (validationErrors.length !== 0) {
      return Curry._2(notify, /* Error */3, {
                  message: "Error while saving",
                  description: validationErrors.join("\n")
                });
    }
    try {
      Curry._1(onSave, Belt_Option.getExn(Js_json.decodeObject(JSON.parse(Belt_Option.getExn(Curry._1(getCodeRef.current, undefined))))));
      return ;
    }
    catch (err){
      return Curry._2(notify, /* Error */3, {
                  message: "Error while saving",
                  description: "Invalid structure"
                });
    }
  };
  return React.createElement(React.Fragment, undefined, React.createElement(Monaco.make, {
                  lang: lang,
                  code: code,
                  path: path,
                  actions: [{
                      id: "uuidPaste",
                      label: "Paste UUID",
                      contextMenuGroupId: "helpers",
                      run: (function (editor) {
                          return Monaco.write(editor, Uuid.v4());
                        })
                    }],
                  notify: notify,
                  getCode: getCode
                }), savePortalOpt !== undefined && utilsPortalOpt !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(ReactPortal.Portal, {
                        node: Caml_option.valFromOption(savePortalOpt),
                        children: React.createElement(Antd.Tooltip, {
                              title: "Save",
                              children: React.createElement(Antd.Button, {
                                    icon: React.createElement(Icons.SaveOutlined, {}),
                                    onClick: onSaveClick
                                  })
                            })
                      }), React.createElement(ReactPortal.Portal, {
                        node: Caml_option.valFromOption(utilsPortalOpt),
                        children: React.createElement(Antd.Select, {
                              defaultValue: lang,
                              onChange: updateLang,
                              children: null
                            }, React.createElement($$Option.make, {
                                  value: /* Json */0,
                                  children: Monaco.Lang.toString(/* Json */0)
                                }), React.createElement($$Option.make, {
                                  value: /* Yaml */1,
                                  children: Monaco.Lang.toString(/* Yaml */1)
                                }))
                      })) : React.createElement(React.Fragment, undefined));
}

var make = Editor;

export {
  make ,
  
}
/* Json Not a pure module */
