// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Helper from "./Helper";

function none(param) {
  
}

function flatMap(prim0, prim1) {
  return Helper.flatMap(prim0, prim1);
}

function set(prim0, prim1, prim2) {
  return Helper.set(prim0, prim1, prim2);
}

var $$Array = {
  flatMap: flatMap,
  set: set
};

export {
  none ,
  $$Array ,
  
}
/* ./Helper Not a pure module */
