export const courseSchema = {
  uri: "http://content.codefinity.com/schema/course-schema.json",
  schema: {
    type: "object",
    additionalProperties: false,
    properties: {
      id: {
        type: "string",
        pattern:
          "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$",
      },
      version: {
        type: "string",
      },
      label: {
        type: "string",
      },
      topics: {
        type: "array",
        items: {
          enum: [
            "Coding Foundations",
            "Data Manipulation",
            "Data Visualization",
            "Machine Learning",
            "Data Science",
            "Algorithms",
            "Probability&Statistics",
            "Algebra",
            "Programming",
            "Web Development",
            "Data Analytics",
            "Computer Science",
          ],
        },
      },
      recommendations: {
        type: "array",
        items: {
          type: "string",
          pattern:
            "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$",
        },
      },
      requirements: {
        type: "array",
        items: {
          type: "string",
          pattern:
            "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$",
        },
      },
      type: { enum: ["course", "project"] },
      state: { enum: ["staging", "production"] },
      level: { enum: ["Beginner", "Intermediate", "Advanced"] },
      description: { type: "string" },
      author: { type: "string" },
      technology: { enum: ["JavaScript", "python", "SQL", "R", "html/css/js","c++"] },
    },
    required: [
      "id",
      "version",
      "label",
      "state",
      "topics",
      "level",
      "description",
      "author",
      "technology",
      "type",
      "recommendations",
      "requirements",
    ],
  },
};
export const sectionSchema = {
  uri: "http://content.codefinity.com/schema/section-schema.json",
  schema: {
    type: "object",
    additionalProperties: false,
    properties: {
      id: {
        type: "string",
        pattern:
          "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$",
      },
      label: {
        type: "string",
      },
      jupyterTargetPath: { type: "string" },
      initScriptPath: { type: "string" },
      quiz: {
        type: "object",
        additionalProperties: false,
        properties: {
          questions: {
            type: "array",
            items: {
              anyOf: [
                {
                  type: "object",
                  additionalProperties: false,
                  properties: {
                    type: { enum: ["question"] },
                    id: {
                      type: "string",
                      pattern:
                        "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$",
                    },
                    question: { type: "string" },
                    code: {
                      type: "array",
                      items: {
                        anyOf: [
                          {
                            type: "string",
                          },
                          {
                            type: "object",
                            additionalProperties: false,
                            properties: {
                              type: {
                                type: "string",
                                enum: ["input", "DnD"],
                              },
                              placeholder: {
                                type: "string",
                              },
                              correct: {
                                type: "string",
                              },
                            },

                            required: ["type", "placeholder", "correct"],
                          },
                        ],
                      },
                    },
                    extraVariants: {
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                    hint: {
                      type: "object",
                      additionalProperties: false,
                      properties: {
                        type: { enum: ["markdown"] },
                        content: { type: "string" },
                      },
                    },
                    output: {
                      type: "string",
                    },
                  },
                  required: ["id"],
                },
                {
                  type: "object",
                  additionalProperties: false,
                  properties: {
                    id: {
                      type: "string",
                      pattern:
                        "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$",
                    },
                    question: {
                      type: "object",
                      additionalProperties: false,
                      properties: {
                        text: { type: "string" },
                        code: { type: ["string", "null"] },
                      },
                      required: ["text"],
                    },
                    correctId: { type: "number" },
                    correctIds: {type:"array",items:{type:"number"}},
                    variants: {
                      type: "array",
                      items: {
                        type: "object",
                        additionalProperties: false,
                        properties: {
                          type: { enum: ["code", "markdown"] },
                          value: { type: "string" },
                        },
                        required: ["type", "value"],
                      },
                    },
                  },
                },
              ],
            },
          },
        },
        required: ["questions"],
      },
      description: { type: "string" },
    },
    required: ["id", "label", "description"],
  },
};
export const chapterSchema = {
  uri: "http://content.codefinity.com/schema/chapter-schema.json",
  schema: {
    type: "object",
    additionalProperties: false,
    properties: {
      type: { type: "string", enum: ["column", "grid"] },
      id: {
        type: "string",
        pattern:
          "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$",
      },
      availability: { enum: ["free", "intermediate", "premium"] },
      label: {
        type: "string",
      },
      items: {
        type: "array",
        items: {
          anyOf: [
            {
              type: "object",
              additionalProperties: false,
              properties: {
                type: {
                  enum: ["carousel"],
                },
                images: { type: "array", items: { type: "string" } },
              },
              required: ["type", "images"],
            },
            {
              type: "object",
              additionalProperties: false,
              properties: {
                type: { enum: ["markdown", "html"] },
                content: { type: "string" },
              },
            },
            {
              type: "object",
              additionalProperties: false,
              properties: {
                type: { enum: ["example"] },
                content: {oneOf:[{ type: "string" },{type:"array",items:{type:"object",properties:{name:{type:"string"}, content:{type:"string"}}}}]},
                output: { type: "string" },
                isRunnable: { enum: [true] },
              },
            },

            {
              type: "object",
              additionalProperties: false,
              properties: {
                type: { enum: ["question"] },
                id: {
                  type: "string",
                  pattern:
                    "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$",
                },
                question: { type: "string" },
                code: {
                  type: "array",
                  items: {
                    anyOf: [
                      {
                        type: "string",
                      },
                      {
                        type: "object",
                        additionalProperties: false,
                        properties: {
                          type: {
                            type: "string",
                            enum: ["input", "DnD"],
                          },
                          placeholder: {
                            type: "string",
                          },
                          correct: {
                            type: "string",
                          },
                        },

                        required: ["type", "placeholder", "correct"],
                      },
                    ],
                  },
                },
                extraVariants: {
                  type: "array",
                  items: {
                    type: "string",
                  },
                },
                hint: {
                  type: "object",
                  additionalProperties: false,
                  properties: {
                    type: { enum: ["markdown"] },
                    content: { type: "string" },
                  },
                },
                output: {
                  type: "string",
                },
              },
              required: ["id", "type"],
            },

              {
                  type: "object",
                  additionalProperties: false,
                  properties: {
                    type: { enum: ["question"] },
                    id: {
                      type: "string",
                      pattern:
                        "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$",
                    },
                    question: {
                      type: "object",
                      additionalProperties: false,
                      properties: {
                        text: { type: "string" },
                        code: { type: ["string", "null"] },
                      },
                      required: ["text"],
                    },
                    correctId: { type: "number" },
                    correctIds: {type:"array",items:{type:"number"}},
                    variants: {
                      type: "array",
                      items: {
                        type: "object",
                        additionalProperties: false,
                        properties: {
                          type: { enum: ["code", "markdown"] },
                          value: { type: "string" },
                        },
                        required: ["type", "value"],
                      },
                    },
                  },
                },

            {
              type: "object",
              additionalProperties: false,
              properties: {
                type: { enum: ["task"] },
                id: {
                  type: "string",
                  pattern:
                    "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$",
                },
                hint: {
                  type: "object",
                  additionalProperties: false,
                  properties: {
                    type: { enum: ["markdown"] },
                    content: { type: "string" },
                  },
                },
                taskCode: {
                  anyOf: [
                    { type: "string" },
                    {
                      type: "array",
                      items: {
                        type: "object",
                        additionalProperties: false,
                        properties: {
                          name: { type: "string" },
                          content: { type: "string" },
                        },
                        required: ["name", "content"],
                      },
                    },
                  ],
                },
                description: {
                  type: "object",
                  additionalProperties: false,
                  properties: {
                    type: { enum: ["markdown"] },
                    content: { type: "string" },
                  },
                },
                solution: {
                  type: "object",
                  additionalProperties: false,
                  properties: {
                    type: { enum: ["example"] },
                    content: { type: "string" },
                    isRunnable: { enum: [true] },
                  },
                },
              },
              required: ["id"],
            },
          ],
        },
      },
    },
    required: ["id", "availability", "label", "items"],
  },
};
